import grapesjs from "grapesjs";
import gjsBlockBasic from "grapesjs-blocks-basic";
import $ from "jquery";
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";
import grapesjsPluginExport from "grapesjs-plugin-export";
import grapesjsStyleBg from "grapesjs-style-bg";
import Cropper from "react-cropper";
import axios from "axios";
import grapesjstuiimageeditor from "grapesjs-tui-image-editor";
import gjsgetinlinedhtml from "grapesjs-preset-newsletter";
import gjsPresetWebpage from "grapesjs-preset-webpage";
import {
  addEditorCommand,
  deviceManager,
  layerManager,
  panels,
  scripts,
  selectorManager,
  storageSetting,
  styleManager,
  styles,
  toggleSidebar,
  traitManager,
} from "./geditor_utils";
import tailwindComponent from "../plugins/tailwind";
import swiperComponent from "../plugins/swiper";
import chartLibComponent from "../plugins/charts";

const geditorConfig = (assets, pageId) => {
  $(".panel__devices").html("");
  $(".panel__basic-actions").html("");
  $(".panel__editor").html("");
  $("#blocks").html("");
  $("#styles-container").html("");
  $("#layers-container").html("");
  $("#trait-container").html("");

  // Content for Preview
  const navbar = $("#navbar");
  const mainContent = $("#main-content");
  const panelTopBar = $("#main-content > .navbar-light");

  const editor = grapesjs.init({
    container: "#editor",
    blockManager: {
      appendTo: "#blocks",
    },
    exportWrapper: true,
    noticeOnUnload: 0,
    styleManager: styleManager,
    layerManager: layerManager,
    traitManager: traitManager,
    selectorManager: selectorManager,
    panels: panels,
    deviceManager: deviceManager,
    assetManager: { assets: assets, upload: false },
    storageManager: storageSetting(pageId),
    pageManager: {
      pages: [
        {
          id: pageId,
          //styles: `.my-class { color: red }`, // or a JSON of styles
         // component: '<div class="my-class">My element</div>', // or a JSON of components
        }
     ]
    },
    
    canvas: {
      styles: styles,
      scripts: scripts,
    },
    plugins: [
      tailwindComponent,
      gjsBlockBasic,
      swiperComponent,
      grapesjsBlockBootstrap,
      grapesjsPluginExport,
      grapesjsStyleBg,
      chartLibComponent,
      grapesjstuiimageeditor,
     // gjsPresetWebpage,
      gjsgetinlinedhtml
    ],
    pluginsOpts: {
      tailwindComponent: {},
     //   gjsPresetWebpage: {},
      //gjsBlockBasic: {},
      swiperComponent: {},
      gjsgetinlinedhtml:{
      // //  modalTitleImport: 'Import template',
       },
      gjsBlockBasic: {
        blocks: {
       //   components:false,
       
       //   card_container:true,
        },
        blockCategories: {
          components:false,
           
        },
        labels: {
     //     text:'jass'
        },
      //  formPredefinedActions: null,
      },
      grapesjsPluginExport: {},
      grapesjsStyleBg: {},
      chartLibComponent: {},
      grapesjstuiimageeditor: {
        config: {
          includeUI: {
            initMenu: 'filter',
          },
        },
      }
      
     // gjsPresetWebpage: {},
    },
    
   
    
  });

  const devices = editor.DeviceManager.getDevices();
console.log(JSON.stringify(devices));

  //const removed = editor.deviceManager.remove('device-id');
  

 // editor.blockManager.remove('BLOCK_ID');
  // editor.BlockManager.add('Cards', {
  //   label: 'Cards',
  //   content: 'Put your content here',
  //   category:{
  //   label: 'MyCategory',
  //   order: 1,
  //   open: true
  //   },
  //   attributes: {
  //   title: 'Insert Cards',
  //   class: 'fa fa-cube'
  //   }
  // });
 // editor.blockManager.remove((block.attributes.id);
 const blockManager = editor.Blocks;
 //const removed = blockManager.remove('card');
 //const removed2 = blockManager.remove('card_container');
  const blocks = editor.BlockManager.getAll();
        blocks.map(block => {
          //blockManager.remove((block.attributes.id);
         // console.log(block.attributes.id);
            // if(block.attributes.id === 'card'){
            //   //blocks.remove(block.attributes.id);
            //     // block.attributes.category = {
            //     //     label:"cat 1",
            //     //     order:1
            //     // }
            // }
        })
  var newComps = editor.DomComponents; $.get(`https://referral.redacted.inc/scheduler/loadTemplatepagescontent/${pageId}`, function (e) { newComps.setComponents(e); }); window.editor.setComponents(newComps);
  window.editor.setComponents(newComps);
  editor.on('load', () => {
    //const rs = editor.StorageManager.get('remote-storage');
  //  var newComps = editor.DomComponents; $.get('http://localhost/strategic/morning-live/morning-invest/scheduler/loadTemplatepagescontent/2', function (e) { newComps.setComponents(e); }); window.editor.setComponents(newComps);
   //editor.setComponents(newComps);
    // newComps.load(res => {
    //  console.log(res);
       //editor.setComponents(JSON.parse(res['mycustom-components'].replace(/^"|"$/g, "")));
     //  editor.setStyle(JSON.parse(res['gjs-styles'].replace(/^"|"$/g, "")));
 //   }, err => {
      // console.log("An error occured", err);
   //});
 });
 editor.on('load', (some, argument) => {
 // const wrapper = editor.DomComponents.getWrapper();
 // console.log(wrapper);
});

const sessionStoragePlugin = (editor) => {
  // As sessionStorage is not an asynchronous API,
  // the `async` keyword could be skipped
  editor.Storage.add('session', {
    async load(options = {}) {
      console.log(sessionStorage.getItem(options.key));
      return JSON.parse(sessionStorage.getItem(options.key));
    },

    async store(data, options = {}) {
      sessionStorage.setItem(options.key, JSON.stringify(data));
    }
  });
};


  
  //console.log(newComps);
 // const wrapper = editor.DomComponents.getWrapper();
 // console.log(wrapper.mycustom-html);
 // editor.load(res => console.log('Load callback'));
  //this.editor.load=(res => console.log('Load callback'));
 // const wrapper = editor.DomComponents.getWrapper();
 // console.log(wrapper);
//editor.setComponents('<div class="cls">New component</div>');
  var pfx = editor.getConfig().stylePrefix;
      var modal = editor.Modal;
      var cmdm = editor.Commands;
      var codeViewer = editor.CodeManager.getViewer('CodeMirror').clone();
      var pnm = editor.Panels;
      var container = document.createElement('div');
      var btnEdit = document.createElement('button');
          codeViewer.set({
              codeName: 'htmlmixed',
              readOnly: 0,
              theme: 'hopscotch',
              autoBeautify: true,
              autoCloseTags: true,
              autoCloseBrackets: true,
              lineWrapping: true,
              styleActiveLine: true,
              smartIndent: true,
              indentWithTabs: true
          });
          btnEdit.innerHTML = 'Import';
          btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import';
          btnEdit.onclick = function() {
              var code = codeViewer.editor.getValue();
              editor.DomComponents.getWrapper().set('content', '');
              editor.setComponents(code.trim());
              modal.close();
          };

          cmdm.add('html-import', {
              run: function(editor, sender) {
                  sender && sender.set('active', 0);
                  var viewer = codeViewer.editor;
                  modal.setTitle('Edit code');
                  if (!viewer) {
                      var txtarea = document.createElement('textarea');
                      container.appendChild(txtarea);
                      container.appendChild(btnEdit);
                      codeViewer.init(txtarea);
                      viewer = codeViewer.editor;
                  }
                  modal.setContent('');
                  modal.setContent(container);
                  codeViewer.setContent('');
                  modal.open();
                  viewer.refresh();
              }
          });

      pnm.addButton('options',
          [
              {
                  id: 'import',
                  className: 'fa fa-download',
                  command: 'html-import',
                  attributes: {
                      title: 'Import Your Template'
                  }
              }
          ]
      );

  addEditorCommand(editor);
  editor.on("run:preview", () => {
    console.log("It will trigger when we click on preview icon");
    // This will be used to hide border
    editor.stopCommand("sw-visibility");
    // This will hide the sidebar view
    navbar.removeClass("sidebar");
    // This will make the main-content to be full width
    mainContent.removeClass("main-content");

    // This will hide top panel where we have added the button
    panelTopBar.addClass("d-none");
  });
  editor.on("stop:preview", () => {
    // This event is reverse of the above event.
    console.log("It will trigger when we click on cancel preview icon");
    editor.runCommand("sw-visibility");
    navbar.addClass("sidebar");
    mainContent.addClass("main-content");
    panelTopBar.removeClass("d-none");
  });
  editor.on("component:selected", (component) => {
    const newTool = {
      icon: "fa fa-plus-square",
      title: "Check Toolbar",
      commandName: "new-tool-cmd",
      id: "new-tool",
    };

    const defaultToolbar = component.get("toolbar");
    const checkAlreadyExist = defaultToolbar.find(
      (toolbar) => toolbar.command === newTool.commandName
    );
    if (!checkAlreadyExist) {
      defaultToolbar.unshift({
        id: newTool.id,
        attributes: { class: newTool.icon, title: newTool.title },
        command: newTool.commandName,
      });
      component.set("toolbar", defaultToolbar);
    }
  });
  editor.on("run:open-assets", function () {
    //console.log('gg');
   //var dateNow = 'img-'+Date.now();
   // var dateNow = 'img-'+'jasvir';
    // Using below line i am changing the id of img tag on which user has clicked.
   // editor.getSelected().setId(dateNow);

    // Store active asset manager image id and it's src
   // localStorage.setItem('activeAssetManagerImageId', dateNow);
  });

  editor.on('storage:start:store', (objectToStore) => {
   // console.log(editor.getHtml());
   var htmlWithCss = editor.runCommand('gjs-get-inlined-html');
    var body = {
      html: htmlWithCss,
      //html: editor.getHtml(),
      css: editor.getCss(),
      template_id:editor.Storage.getCurrentStorage().attributes.params.page_id,
  };
  
  $(".temp-saved").show();
  axios.post('https://referral.redacted.inc/scheduler/saveHtml/', body)
    .then(function (response) {
      $(".temp-saved").show();
      $('.temp-saved').delay(2000).fadeOut('slow');
      //console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  });
 


//   editor.on("stop:open-assets", function () {
//     var mainDivId = Date.now();
//     // fetch current active asset manager image
//     var previousImageId = localStorage.getItem('activeAssetManagerImageId');
//     var activatedImage = 'div-' + previousImageId;
//     var previousSrc = localStorage.getItem('activeAssetManagerSrc');
//     // Fetch iframe document. (Here we got the parent document not iframe's)
//     var iframeDocument = document.getElementsByClassName('gjs-frame')['0'].contentDocument;
//     // Fetch activated image's src to check we need to initialised cropper or not
//     var updatedSrc = iframeDocument.getElementById(previousImageId).src;

//     localStorage.setItem("activatedImageSrc", updatedSrc);
//     console.log(iframeDocument);
//     // Check previous and updated image src are same or not. If not then we need to initialised cropper.
//     if (updatedSrc !== previousSrc) {
     
//         var mainDiv = "<div id='" + mainDivId + "'><img src ='" + updatedSrc + "' id ='" + previousImageId  + "' /><button onClick='doneExternal(previousImageId )'>Done</button></div>";
//         iframeDocument.getElementById(previousImageId).replaceWith(mainDiv);
        
//         // Cropper is initialising Here
//     var   cropperInstance = new Cropper(updatedSrc, {
//             viewMode: 3,
//             responsive: true,
//             dragMode: 'move',
//             autoCropArea: 1,
//             restore: false,
//             modal: false,
//             guides: false,
//             highlight: false,
//             cropBoxMovable: false,
//             cropBoxResizable: true,
//             toggleDragModeOnDblclick: false,
//             ready: function () {
//                 iframeWindow.doneButtonExternal = function (n) {
//                     var base64Image = cropperInstance.getCroppedCanvas({
//                         minWidth: 280,
//                         fillColor: "#fff",
//                         imageSmoothingEnabled: !1,
//                         imageSmoothingQuality: "high"
//                     }).toDataURL();
//                     cropperInstance.destroy();
                    
//                     // In below imgModel is getting undefined. Because imgModel is like blank array (Ex. [])
//                     var imgModel = editor.getWrapper().find('img#'+previousImageId)[0];
//                     imgModel.set('src', base64Image);
//                 }
//             }
//         })
//     }
// });

//   editor.BlockManager.get('card').set({
//     content: { style: 'color: "black"; max-width: 10px;' },
// });

  setTimeout(() => {
    let categories = editor.BlockManager.getCategories();
    //console.log(categories);
    categories.each((category) => category.set("open", false));
   
  }, 1000);
  return editor;
};

export default geditorConfig;
